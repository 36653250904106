.introjs-nextbutton, .introjs-prevbutton ,.introjs-tooltipbuttons{
    display: none !important;
}
.introjs-bullets {
    display: none !important;
}
.introjs-tooltiptext {
    padding: 1px;
    padding-top: 0px;
}
/* .introjs-tooltip {
    position: fixed!important;
    width: 100%; height: 100%; top: 0; right: 0; bottom: 0; left: 0;
 } */
  /* .introjs-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.5;
} */
.introjs-tooltip {
   min-width: 82vw;
   margin-top: -50vh !important;
   size: cover;
}
/* .introjs-tooltip {
    width: 100vw !important;    
      height: 100vh !important; 
    top: 0 !important;    
    left: 0 !important;      
    right: 0 !important;    
    bottom: 0 !important;   
    padding: 0 !important; 
    background: rgba(255, 255, 255, 0.95) !important; 
    z-index: 9999 !important; 
    border: none !important; 
    x-shadow: none !important; 
    splay: flex !important; 
    justify-content: center !important;  
    align-items: center !important;      
} */


/* .introjs-tooltip img {
    width: 100vw !important;    
    height: 100vh !important;   
    object-fit: contain !important; 
} */




/* .introjs-tooltip .introjs-closeButton {
    position: absolute !important;
    top: 10px;
    right: 10px;
    z-index: 10000 !important;
    font-size: 24px !important;
    background-color: transparent !important;
    border: none !important;
    color: #000 !important;
    cursor: pointer !important;
    display: block !important; 
} */