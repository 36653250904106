.itemWidth {
    width: 7rem;
}

.cellMinWidth {
    min-width: 3rem;
}

#plantQATable {
    margin: unset;
}