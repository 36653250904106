.tableCss {
    height: 28.5vh;
    overflow-y: auto;
    margin-bottom: .3rem;
}

button.formButton.btn.btn-primary {
    margin-top: 0.2rem;
}

.headerRowSpan {
    margin: 0.5rem 0;
    background-color: #e9ecef;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    color: black;
    position: relative;
}

/* Styles.css */
.draggableItem {
    align-items: center;
    padding: 3px !important;
    margin: 1px 1px .6rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.draggableItemHeader {
    display: flex;
    align-items: center;
    margin: 1px 1px .6rem;
    padding: 0 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    width: 100%;
}

.draggableItemChild {
    display: inline-flex;
    align-items: center;
    margin: 1px 1px .6rem;
    padding: 0 6px;
}

.noPadding {
    padding: unset;
}

.inputFormat {
    width: 26rem;
    margin: 0 .5rem;
}

.deleteIcon {
    cursor: pointer;
    color: #ff0000;
    font-size: .8rem;
}


