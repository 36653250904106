.formBorder {
    border: 1px solid rgba(153, 153, 153, 0.25);
}

.divBorder {
    border: 1px solid rgba(153, 153, 153, 0.25);
}

.listClass{
    padding: 0.1rem 0.9rem;
    cursor: pointer;
}

.divListClass {
    height: 20vh;
    overflow: auto;
}