body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: .75rem;
    line-height: 1.8;
    font-weight: normal;
    height: 100%;
    font-family: 'Poppins', sans-serif;
}

p {
    font-weight: 300;
    line-height: 1.7em;
    color: #999
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch
}

.headerRow {
    border-bottom: 1px solid #0f0fec;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #fff;
    color: #fff;
    transition: all 0.3s;
    border-right: 1px solid #0f0fec;
}

#sidebar.active {
    margin-left: -250px
}

#sidebar .sidebar-header {
    background: #fff;
}

#sidebar ul.components {
    padding: 0px 0px;
}

#sidebar ul p {
    padding: 10px;
    display: block;
    color: #fff
}

#sidebar ul li a {
    padding: 8px 10px;
    display: block;
    color: #000;
}

#sidebar ul li a.inner {
    padding: 8px 10px;
    display: contents;
}

a[data-toggle="collapse"] {
    color: #fff;
    position: relative;
}

.dropdown-toggle::after {
    color: #000;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

ul ul a {
    padding-left: 30px !important;
    background: #0f0fec
}

ul.CTAs {
    padding: 20px
}

ul.CTAs a {
    text-align: center;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px
}

a.download,
a.download:hover {
    background: #0f0fec;
    color: #fff
}

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s
}

.content-wrapper {
    padding: 15px
}

.btn-info {
    color: #fff;
    background-color: #0f0fec;
    border-color: #0f0fec;
}

.btn-info:hover {
    color: #fff;
    background-color: #0f0fec;
    border-color: #0f0fec;
}

.bg-c-blue {
    font-weight: 600;
    background: linear-gradient(45deg, rgb(102, 102, 238), #ffffff, rgb(102, 102, 238) 200px);
}

.bg-c-cyan {
    background: linear-gradient(45deg, cyan, white, cyan 200px);
}

.bg-c-yellow {
    background: linear-gradient(45deg, #f7f706f0, white, #f7f706f0 200px);
}

.bg-c-purple {
    background: linear-gradient(45deg, purple, #ffffff, purple 200px);
}

.bg-c-green {
    background: linear-gradient(45deg, rgb(176, 247, 176), #ffffff, rgb(176, 247, 176) 200px);
}

.bg-c-orange {
    background: linear-gradient(45deg, #FFB64D, #ffcb80 200px);
}

.bg-c-pink {
    background: linear-gradient(45deg, #FF5370, #ffffff, #ff869a 200px);
}

.bg-c-red {
    background: linear-gradient(45deg, #ffffff, #fbd8da75);

}

.navLink {
    color: #fff !important;
    font-weight: 500;
    display: block;
    text-decoration: none;
    margin: .5rem;
}

.profile-dropdown-toggle::after {
    color: #000;
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 1px solid #1a80bb;
    opacity: 1;
}

.info-dialog-state {
    position: absolute;
    z-index: 1111;
    top: 100px;
    left: 250px;
    background-color: #dfeeff;
    color: white;
    width: calc(100% - 300px);
    height: calc(100% - 200px);
    border-radius: 8px;
    border: 1px solid #0f0fec;
}

mt-2 {
    margin-top: 16px;
}

.btn {
    border: none !important;
    color: white;
}

.btn:focus, input:focus {
    box-shadow: none !important;
}

.card {
    height: 28vh;
}

.col-sm-12 {
    padding-left: 0;
}

.invalidFeedback {
    width: 100%;
    margin-top: 0.25rem;
    color: #dc3545;
}

.borderRow {
    border-top: 4px solid blue;
}

.border-left.col-2 {
    border-right: 1px solid #0f0fec;

}

.card-body {

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 300px; */
    flex: 1 1 auto;
    padding: 1rem 1rem;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    background: linear-gradient(45deg, #ffffff, #fbd8da75);
}

.hrClass {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 1px solid #0f0fec;
    opacity: 1;
}

.navBorder {
    border: 1px solid #1a80bb;
}

.navBorder > .btn {
    border-radius: unset;
    text-align: left;
}

.themeSelector > #dropdown-basic-button {
    color: #000000;
    background-color: #ffffff;
}

.mainCol {
    overflow-y: auto;
    margin-top: 1vh;
    height: 87vh;
}

.themeSelector.show > .dropdown-menu {
    max-height: 40vh;
    overflow: auto;
}

.form-control {
    padding: .1rem .2rem;
}

.dropdown-menu {
    --bs-dropdown-font-size: .75rem;
}

.btn {
    --bs-btn-font-size: .75rem;
}

.input-group-text, .form-control {
    font-size: .75rem;
}

.formContainer > .row {
    margin-left: unset;
    margin-right: unset;
}

.col-form-label {
    padding-bottom: unset;
    padding-top: unset;
    color: gray;
    font-style: italic;
    font-size: .75rem;
    font-weight: bold;
}

.formContainer {
    background: rgba(153, 153, 153, 0.25);
    overflow-y: auto;
    max-height: 72vh;
}

@media screen and (min-width: 653px) {
    .headerRow {
        height: 9vh;
    }
}

/*Message*/
.messageHeaderRow {
    justify-content: end;
    margin-bottom: .4rem;
    padding: 0 .8rem;
}

.messageRow {
    color: white;
    text-align: center;
    background-color: green;
    padding: unset;
    border-radius: 1px;
    border: green;
}

.errorMessageRow {
    color: white;
    text-align: center;
    background-color: red;
    padding: unset;
    border-radius: 1px;
    border: red;
}

/*Header*/

.navbar {
    padding: 15px 10px;
    background: #0f0fec;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1)
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none
}

.navbarHeader {
    align-items: center; /* center items vertically, in this case */
    background: #0d6efd;
    border: none;
    border-radius: 0;
    height: 100%;
    color: white;
}

/*Card Container*/
.cardContainer {
    justify-content: unset;
    font-size: 1.1rem;
}

/*Bootstrap CSS*/
.bodyContainer > .col-sm-10, .bodyContainer > .col-sm-12 {
}

.headerRow > .col-sm-2, .headerRow > .col-sm-10, .headerRow > .col-sm-12 {
}

.form-select {
    font-size: .75rem;
    padding: 0.1rem 0.2rem;
}

/* Form validation */
.formErrorDiv {
    color: #842029;
    background-color: #f8d7da;
    border: #f8d7da;
    border-radius: 1px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
}

.errorDiv {
    border-color: #dc3545;
}

input.form-control.errorDiv:focus, select.form-select.errorDiv:focus {
    color: #212529;
    background-color: #fff;
    border-color: #dc3545;
}

/*select.form-select.errorDiv:focus {
    box-shadow: 0 0 0 0.25rem #dc3545;
}*/

.centerAlignmentTag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.surveyHeaderClass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    z-index: 999;
    background-color: #00bf6f;
    font-size: 1rem;
    top: 0;
    height: 130px;
}

.surveyErrorHeaderClass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    z-index: 999;
    background-color: #f7f8fa;
    font-size: 1rem;
    top: 0;
    height: 130px;
}

.surveyHeader {
    background-color: blanchedalmond;
    font-size: .9rem;
    font-weight: bold;
}

.addCursorPointer {
    cursor: pointer;
}

#sessionDate {
    padding: 0.35rem 0.2rem;
    max-width: 6rem !important;
}

.sample-tutorial {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.sample-spreadsheets {
    width: 100%;
    height: 90%;
}

.exportableReport {
    border: 1px solid black;
    border-radius: 22px 22px 0 0;
    background-color: #0f0fec;
    height: 44px;
    font-size: 18px;
    font-weight: normal;
    line-height: 43px;
    white-space: nowrap;
}

.exportableReportHeader {
    padding: 0 11px;
    background-color: #0f0fec;
    height: 44px;
    font-size: 18px;
    font-weight: normal;
    line-height: 43px;
    position: relative;
    margin: 0;
    white-space: nowrap;
    color: white;
}

.exportableReportButton {
    height: inherit;
    line-height: inherit;
    background: none;
    border: none;
    transition: background-color 0.1s linear;
    font-size: inherit;
    outline: none;
    color: inherit;
}

.gc-no-user-select > table {
    border: 1px solid black;
}

.divWidth{
    width: 94%;
}

.selectDiv {
    background-color: #fff;
    color: black;
    border-radius: 2px;
    position: absolute;
    z-index: 3;
    height: 30vh;
    overflow-y: auto;
    border: 1px solid black;
    min-width: 14vw;
}

.selectDiv > div:hover {
    background-color: #1a80bb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.divOverlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}

.container-md {
    max-width: 99%;
}

/*-----CSS for Screen Fit--------*/

.container-fluid {
    height: inherit;
}

/*Body*/
.bodyContainer {
    height: 91vh;
}

/*Sidebar*/
.sidebarCol {
    padding-left: unset;
    padding-right: unset;
}

/*Header*/
.userCol{
    text-align: end;
    padding-right: 3rem;
}

.companyLogo {
    width: 100%;
    text-align: center;
    height: 100%;
}

.sidebar-header {
    text-align: center;
    width: 100%;
    height: 100%;
}

.dateApplyButton {
    align-items: center;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 3px solid #ced4da;
    border-radius: 0.375rem;
}

.subNavContainer{
    padding-left: .3rem;
    background: #0d6efd;
    color: white;
}

.reportLabel{
    font-size: .72rem;
}

.enterprisePadding {
    padding-left: .63rem;
}

/*Loader*/
.divLoader {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}
.loadingSpan {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loadingSpan span {
    animation: loadingSpan 1.4s infinite alternate;
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
}
@keyframes loadingSpan {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.pieChartClass {
    height: 22vh;
    min-width: 10vw;
    width: 25%;
    font-size: .55rem;
}

.lineChartClass {
    height: 20vh;
    min-width: 10vw;
    width: 45vw;
    font-size: .55rem;
}

.starCss {
    font-size: 1rem;
}
/*

.divLoader {
    width: 100%;
    height: 100%;
    background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loadingSpan {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}*/

.warn {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
}

.warningMsg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid orangered;
    background-color: rgb(210, 153, 124);
}

.devBackground {
    background-color: #CCCC00 !important;
    color: #FFFFFF;
}

.prodBackground {
    background-color: #19315b !important;
    color: #FFFFFF;
}

.navLinkDev {
    color: #FFFFFF;
    font-weight: 500;
    display: block;
    text-decoration: none;
    margin: .5rem;
}

.navHeaderTitle{
    text-align: center;
    margin: unset;
}

.micOff {
    color: #FF0000;
}

.micOn {
    color: #808080;
}

.listButton {
    background: blue;
    border: 1px solid;
    border-radius: 10px;
}

.divListCSS{
    max-height: 40vh;
    overflow: auto;
    border: 1px solid rgba(153, 153, 153, 0.25);
    margin-bottom: 1rem;
}

.buttonClass {
    margin: 0.5rem;
    padding: 0.4rem;
}

.formContainer .onButton {
    background-color: #0d6efd;
}

.formContainer .offButton {
    background-color: #808080;
}

.divReportListCSS{
    overflow: auto;
    border: 1px solid rgba(153, 153, 153, 0.25);
    margin-bottom: 1rem;
}

.divSurveyListCSS{
    max-height: 20vh;
    overflow: auto;
    border: 1px solid rgba(153, 153, 153, 0.25);
    margin-bottom: 1rem;
}

.divSurveyCSS{
    max-height: 65vh;
    overflow: auto;
    border: 1px solid rgba(153, 153, 153, 0.25);
    margin-bottom: 1rem;
}

.navLeftPadding {
    padding-left: 1rem;
}

.loginImageBorder {
}

.errorLogRow {
    overflow-y: auto;
    max-height: 75vh;
}

.datePickerInput {
    padding: 0.35rem 0.2rem;
    display: block;
    width: 100%;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.selectedNavDisplay {
    color: #0d6efd;
}

.fontArrowDiv{
    width: 3rem;
}

.divAlignItem {
    display: flex;
    align-items: center;
}

#addSiteRequest > .formContainer {
    max-height: inherit;
}

.divCheckbox {
    display: flex;
    align-items: center;
}

/*Color for chart screen*/
.fixedHeaderTable {
    width: 100%;
    border-collapse: collapse;
}

.fixedHeaderTable thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    border-top: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
    text-align: center;
    background-color: #e9ecef;
    box-sizing: border-box;
    font-size: .75rem;
    color: #000;
    font-weight: unset;
}

.fixedHeaderTable th,
.fixedHeaderTable td {
    border: 1px solid #cbcbcb;
    text-align: center;
}

.subChartHeader {
    justify-content: space-between;
    background-color: #e9ecef;
    color: #1a80bb;
    box-sizing: border-box;
}

.subChartHeaderSpan {
    color: #1a80bb;
}

/*Button Custom CSS*/


.buttonCss {
    background-color: #1a80bb;
    margin: 0 2px;
}

.buttonDisableCss {
    background-color: #6c757d;
    margin: 0 2px;
}

.buttonCss.btn-check:focus + .btn, .buttonCss.btn:focus, .buttonCss.btn:hover {
    background-color: #1a80bb;
}

.buttonDisableCss.btn-check:focus + .btn, .buttonDisableCss.btn:focus, .buttonDisableCss.btn:hover,
.btn.disabled, .btn:disabled, fieldset:disabled .btn{
    background-color: #6c757d;
    opacity: 1;
}

.list-group-item.active {
    background-color: #1a80bb;
    border-color: #1a80bb;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="url"]:focus,
textarea.form-control:focus,
select.form-select:focus{
    border-color: #1a80bb;
    box-shadow: 0 0 1px #1a80bb; /* Optional: add a shadow for better visibility */
}

select option:checked,
select option:focus,
select option:hover,
select option:hover,
select option.selected
{
    background-color: #1a80bb !important;
}

/* Custom checkbox styles */
/* Custom checkbox styles */
input[type="checkbox"] {
    accent-color: #1a80bb; /* This property is widely supported and changes the checkbox color in modern browsers */
}

/* Fallback for browsers that don't support accent-color */
input[type="checkbox"]:checked::before {
    background-color: #1a80bb;
    border: 1px solid #1a80bb;
}

/* Custom radio styles */
input[type="radio"] {
    accent-color: #1a80bb;
}

.optionCss:hover,
.optionCss:focus{
    background-color: #1a80bb !important;
}

.rangeSelection {
    padding: .375rem 0.1rem .375rem .2rem;
    max-width: 7rem;
}

/* For Safari */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        select option:hover {
            background-color: #1a80bb;
        }
    }
}
