#loginContainer {
    width: fit-content;
    position: fixed;
    top: 0;
    bottom: 0;
    height: fit-content;
    left: 0;
    right: 0;
    margin: auto;
    background: #c5d5cb;
    border-radius: 5px;
    text-align: left;
}

.loginHeader {
text-align: center;
}

.btn.loginButton {
background: #e3b04b !important;
border: 1px solid #e3b04b !important;
color: #fff !important;
width: 100%;
}

.forgotButton {
text-align: right;
}

#login-form-powered-by {
font-size: 12px;
color: darkblue;
font-weight: 600;
}

#login-form-powered {
font-size: 10px;
color: #000
}

#forgetPassword {
color: #007bff;
text-decoration: none;
background-color: transparent;
}

.invalid-div {
width: 100%;
margin-top: 0.25rem;
font-size: .875em;
color: #dc3545;
}

.logoImage {
    width: 100%;
    text-align: center;
    height: 20%;
}
