.qualityTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #403F3B;
}

.tableHeader{
    text-align: center;
    background-color: #e9ecef;
    color: black;
    box-sizing: border-box;
    border: 2px solid #cbcbcb;
}

.qualityTable th.shrink {
    width: 1vw;
}

.qualityTable th {
    height: 33px;
    margin: 0;
    background-color: #403F3B;
    white-space: nowrap;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;
    text-transform: uppercase;
    color: #e3e0cf;
    position: relative;
    padding: 0 12px;
    overflow: hidden;
}

.qualityTable tr:not(:last-child) td {
    border-bottom: 1px solid #a4cffc;
}
.qualityTable td:last-child, .Dashboard td:first-child {
    white-space: nowrap;
    padding: 0 1em;
}
.qualityTable td:not(:last-child) {
    border-right: 1px solid #edf4fa;
}
.qualityTable td {
    padding: 0 0.25em;
    box-sizing: border-box;
    line-height: 75px;
}

.chartRow {
    height: 20vh;
    width: 15vw;
    font-size: .6rem;
}

.colClass {
    border: 1px solid #ced4da;
    padding-right: unset;
    padding-left: unset;
    border-radius: 12px;
}

.qualityAudit {
    font-size: .6rem;
}