.reportModal .modal-dialog {
    max-width: 95%;
    min-height: 90%;
}

.reportModalBody {
    padding: unset;
    max-height: 80vh;
    min-height: 40vh;
    overflow-y: scroll;
}

.reportModalBody .table {
    margin-bottom: unset;
}

.reportModalHeader, .reportModalFooter {
    padding: .3rem;
}

.reportModal .modal-content {
    border: 4px solid #e9ecef;
}

div#contained-modal-title-vcenter {
    width: 94%;
    text-align: center;
}

.reportModalTableContainer{
    max-width: 100rem;
}

.reportModalTableContainer .table-responsive th {
    min-width: 8rem;
}