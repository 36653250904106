ul > li > span > :hover {
    cursor: pointer;
    color: #027afe;
}

table.sam-dashboard {
    width: 100%;
    border-collapse: collapse;
    font-family: Calibri, sans-serif;
    color: black;
}

table.sam-dashboard thead th {
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    background-color: #e9ecef;
    color: black;
    box-sizing: border-box;
    border: 2px solid #cbcbcb;
}

table.sam-dashboard thead tr.heading {
    font-size: .9rem;
}

table.sam-dashboard > tbody > tr > td {
    height: 75px;
    box-sizing: border-box;
    border: 1px solid #cbcbcb;
    border-top: none;
    padding: 0;
    text-align: center;
}

table.sam-dashboard > thead > tr.heading > th {
    border-width: thick thick thin thick;
}

table.sam-dashboard .left-border {
    border-left-width: thick;
}

table.sam-dashboard .right-border {
    border-right-width: thick;
}

table.sam-dashboard > tbody > tr > td {
    border-bottom-width: thick;
}

a.link-list {

    text-transform: capitalize;
    display: block;
    text-decoration: underline;

}

a.link-list:hover {
    background-color: #e9ecef;
}

a.link-list p {
    font-weight: bold;
    margin: 0;
}

ul.ulTree {
    padding-left: unset;
    margin-bottom: unset;
}

.leftContainer {
    height: calc(100% - 44px);
    overflow-y: auto;
    box-sizing: border-box;
}

.containerHeader {
    margin: 0;
    background-color: #e9ecef;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1rem;
    line-height: 44px;
    text-transform: uppercase;
    color: black;
    position: relative;
    padding: 0 12px;
}

.samHeader {
    margin: auto;
    background-color: #e9ecef;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1rem;
    line-height: 44px;
    text-transform: uppercase;
    color: black;
    position: relative;
    padding: 0 .5rem;
}

.headerInput {
    height: 4vh;
    margin: 0.5rem;
    border-radius: 6px;
    width: 50%;
}

.sectionContent{
    width: 100%;
    border-collapse: collapse;
    font-family: Calibri, sans-serif;
    color: black;
    overflow-x: auto;
}


ul.ulTree label{
    display: contents;
}