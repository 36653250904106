.graphBorder {
    height: 20vh;
    width: 45VW
}

.graphArea {
    width: 50%;
    border: 1px solid gray;
    padding: unset;
    margin: unset;
}

.facilityOverview {
    width: 100%;
    text-align: center;
    margin: unset;
}

.facilityHeader {
    white-space: nowrap;
    font-size: 18px;
    text-transform: uppercase;
    color: black;
    position: relative;
    margin: unset;
    background-color: #e9ecef;
}

#subPadding {
    padding-left: 1rem;
    text-align: start;
}

.recharts-reference-line > tspan {
    float: start;
}

.columnWidthEqual {
    width: 33vw;
}

.sortChartHeader {
    display: flex;
    justify-content: space-between;
    background-color: #e9ecef;
    color: black;
    box-sizing: border-box;
}

.multiColPosition {
    background-color: #fff;
    color: black;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    overflow-y: auto;
    border: 1px solid black;
    min-width: 14vw;
}

.colChartClass {
    height: 30vh;
    min-width: 10vw;
    width: 100%;
    font-size: .55rem;
}

.subHeaderCss {
    color: #1a80bb;
}

.subHeaderIconCss {
    color: #a00000;
    font-weight: 800;
    font-size: .75rem;
}

.fullScreenChartCss, .fullScreenChartCss > .modal-dialog > .modal-content {
    width: 90vw;
    height: 90vh;
    margin: auto;
}

.colBorder {
    border: 1px solid #cbcbcb;
    padding: unset;
}

.colBorder > .colChartClass {
    padding: 1rem;
}

.headerTitleCss {
    text-align: center;
    background-color: #e9ecef;
    color: black;
    box-sizing: border-box;
    border: 1px solid #cbcbcb;
    font-size: .75rem;
}

.eyeFontCss {
    font-size: 1.5rem;
    color: #000000;
}

/*Search*/
.searchInput > div > .form-control, .searchInput > label{
    padding: .375rem .75rem;
}

.searchInput {
    margin: .5rem 0;
}

/*Sticky header*/
.tableContainer {
    max-height: 70vh; /* Adjust the height as needed */
    overflow-y: auto;
    border-top: 1px solid #ccc; /* Add top border to container */
}

.colTitle {
    min-width: 50px; /* Fixed width for Avg column */
    max-width: 50px; /* Fixed width for Avg column */
    width: 50px; /* Fixed width for Avg column */
    word-wrap: break-word; /* Break word if exceeding column width */
}

.colChart {
    width: 11vw; /* Fixed width for Avg column */
    max-width: 11vw; /* Fixed width for Avg column */
}

.colSentiment {
    width: 1vw; /* Fixed width for Avg column */
    word-wrap: break-word; /* Break word if exceeding column width */
}

.arrowBlueFontCss {
    font-size: 1.5rem;
    color: #19315b;
}

.arrowRedFontCss {
    font-size: 1.5rem;
    color: #a00000;
}

.arrowGreenFontCss {
    font-size: 1.5rem;
    color: #2C5E1A;
}
