ul, #ulCheckbox {
    list-style-type: none;
}

.formColor {
    background: #eff2f5;
}

#ulCheckbox {
    padding-left: unset;
}