

table {
    width: 100%;
    margin-bottom: unset;
}

.table > :not(caption) > * > * {
    padding: unset;
}

#customTableBody td {
    padding-left: 0.2rem;
}

.table-responsive th {
    background: #e9ecef;
    min-width: 2.3rem;
}

thead {
    max-height: 1vh;
}

.table>thead {
    vertical-align: bottom;
    text-align: center;
}

tbody, td, tfoot, th, thead, tr {
    vertical-align: middle;
}

.table-striped>tbody>tr>td:not(.text-center) {
    padding-left: .2rem;
}

.enableCheckbox {
    background-color: #0cc70c;
    border: 1px solid green;
    vertical-align: middle;
}

.disableCheckbox {
    background-color: #c73c21;
    border: 1px solid red;
    vertical-align: middle;
}

.checkboxDiv {
    width: 1.5rem;
    margin: .1rem auto;
}

.customTableResponsive > .table-responsive {
    overflow-y: auto;
    max-height: 72vh;
}

.table-responsive th:first-child {
    background: #e9ecef;
    min-width: 2.3rem;
    width: 5rem;
}

.eyeIcon > .cssCursor {
    cursor: pointer;
}

/*
thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    overflow-y: scroll;
}

tbody {
    display: block;
    overflow-y: auto;
    table-layout: fixed;
    max-height: 68vh;
}

tr th:last-child {
    padding-right: 3rem;
}

tr td:last-child, tr th:last-child {
    width: 2rem;
}

tr td:nth-last-child(2), tr th:nth-last-child(2) {
    width: 2.5rem;
}

tr td:first-child, tr th:first-child {
    width: 6rem;
}
*/
