
#dropdown-basic-button {
    padding: 0.5rem 1rem;
}

.show > .dropdown-menu {
    max-height: 45vh;
}

.linkCol a {
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.linkCol > .dropdown {
    margin-bottom: .4rem;
}

.linkCol > .dropdown > .dropdown-menu.show {
    overflow-y: auto;
    max-height: 45vh;
}