.colBorder {
    border: 1px solid #808080;
    padding: unset;
}

.rowMarginCss {
    margin: 10px;
}

.summaryTable >.customTableResponsive > .table-responsive {
    overflow-y: unset;
    max-height: unset;
}

.summaryTable > .customTableResponsive > .table-responsive table td, .summaryTable > .customTableResponsive > .table-responsive table th {
    border: 1px solid #dee2e6 !important;
}

.summaryReportModal {
    padding-top: unset;
    padding-bottom: unset;
    max-height: 80vh;
    min-height: 40vh;
    overflow-y: scroll;
}

.summaryReportDiv .modal-dialog {
    max-width: 95%;
    min-height: 90%;
}

.marginUnset {
    margin-left: unset;
    margin-right: unset;
}

.chartArea {
    height: 25vh; /* Default for mobile */
    width: 100%;
    padding: 10px;
}

@media (min-width: 768px) {
    .chartArea {
        height: 40vh; /* For screens wider than 768px (iPad and up) */
    }
}